/**
 * This are the Tailwind's flex styles.
 *
 * You can see the styles here:
 * https://tailwindcss.com/docs/flexbox-display
 */

.flex {
    display: flex !important
}

.inline-flex {
    display: inline-flex !important
}

.flex-row {
    flex-direction: row !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-col {
    flex-direction: column !important
}

.flex-col-reverse {
    flex-direction: column-reverse !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.flex-no-wrap {
    flex-wrap: nowrap !important
}

.items-start {
    align-items: flex-start !important
}

.items-end {
    align-items: flex-end !important
}

.items-center {
    align-items: center !important
}

.items-baseline {
    align-items: baseline !important
}

.items-stretch {
    align-items: stretch !important
}

.self-auto {
    align-self: auto !important
}

.self-start {
    align-self: flex-start !important
}

.self-end {
    align-self: flex-end !important
}

.self-center {
    align-self: center !important
}

.self-stretch {
    align-self: stretch !important
}

.justify-start {
    justify-content: flex-start !important
}

.justify-end {
    justify-content: flex-end !important
}

.justify-center {
    justify-content: center !important
}

.justify-between {
    justify-content: space-between !important
}

.justify-around {
    justify-content: space-around !important
}

.content-center {
    align-content: center !important
}

.content-start {
    align-content: flex-start !important
}

.content-end {
    align-content: flex-end !important
}

.content-between {
    align-content: space-between !important
}

.content-around {
    align-content: space-around !important
}

.flex-1 {
    flex: 1 1 0% !important
}

.flex-auto {
    flex: 1 1 auto !important
}

.flex-initial {
    flex: 0 1 auto !important
}

.flex-none {
    flex: none !important
}

.flex-grow {
    flex-grow: 1 !important
}

.flex-shrink {
    flex-shrink: 1 !important
}

.flex-no-grow {
    flex-grow: 0 !important
}

.flex-no-shrink {
    flex-shrink: 0 !important
}

@media (min-width: 576px) {
    .sm\:flex {
        display: flex !important
    }

    .sm\:inline-flex {
        display: inline-flex !important
    }

    .sm\:flex-row {
        flex-direction: row !important
    }

    .sm\:flex-row-reverse {
        flex-direction: row-reverse !important
    }

    .sm\:flex-col {
        flex-direction: column !important
    }

    .sm\:flex-col-reverse {
        flex-direction: column-reverse !important
    }

    .sm\:flex-wrap {
        flex-wrap: wrap !important
    }

    .sm\:flex-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .sm\:flex-no-wrap {
        flex-wrap: nowrap !important
    }

    .sm\:items-start {
        align-items: flex-start !important
    }

    .sm\:items-end {
        align-items: flex-end !important
    }

    .sm\:items-center {
        align-items: center !important
    }

    .sm\:items-baseline {
        align-items: baseline !important
    }

    .sm\:items-stretch {
        align-items: stretch !important
    }

    .sm\:self-auto {
        align-self: auto !important
    }

    .sm\:self-start {
        align-self: flex-start !important
    }

    .sm\:self-end {
        align-self: flex-end !important
    }

    .sm\:self-center {
        align-self: center !important
    }

    .sm\:self-stretch {
        align-self: stretch !important
    }

    .sm\:justify-start {
        justify-content: flex-start !important
    }

    .sm\:justify-end {
        justify-content: flex-end !important
    }

    .sm\:justify-center {
        justify-content: center !important
    }

    .sm\:justify-between {
        justify-content: space-between !important
    }

    .sm\:justify-around {
        justify-content: space-around !important
    }

    .sm\:content-center {
        align-content: center !important
    }

    .sm\:content-start {
        align-content: flex-start !important
    }

    .sm\:content-end {
        align-content: flex-end !important
    }

    .sm\:content-between {
        align-content: space-between !important
    }

    .sm\:content-around {
        align-content: space-around !important
    }

    .sm\:flex-1 {
        flex: 1 1 0% !important
    }

    .sm\:flex-auto {
        flex: 1 1 auto !important
    }

    .sm\:flex-initial {
        flex: 0 1 auto !important
    }

    .sm\:flex-none {
        flex: none !important
    }

    .sm\:flex-grow {
        flex-grow: 1 !important
    }

    .sm\:flex-shrink {
        flex-shrink: 1 !important
    }

    .sm\:flex-no-grow {
        flex-grow: 0 !important
    }

    .sm\:flex-no-shrink {
        flex-shrink: 0 !important
    }
}

@media (min-width: 768px) {
    .md\:flex {
        display: flex !important
    }

    .md\:inline-flex {
        display: inline-flex !important
    }

    .md\:flex-row {
        flex-direction: row !important
    }

    .md\:flex-row-reverse {
        flex-direction: row-reverse !important
    }

    .md\:flex-col {
        flex-direction: column !important
    }

    .md\:flex-col-reverse {
        flex-direction: column-reverse !important
    }

    .md\:flex-wrap {
        flex-wrap: wrap !important
    }

    .md\:flex-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .md\:flex-no-wrap {
        flex-wrap: nowrap !important
    }

    .md\:items-start {
        align-items: flex-start !important
    }

    .md\:items-end {
        align-items: flex-end !important
    }

    .md\:items-center {
        align-items: center !important
    }

    .md\:items-baseline {
        align-items: baseline !important
    }

    .md\:items-stretch {
        align-items: stretch !important
    }

    .md\:self-auto {
        align-self: auto !important
    }

    .md\:self-start {
        align-self: flex-start !important
    }

    .md\:self-end {
        align-self: flex-end !important
    }

    .md\:self-center {
        align-self: center !important
    }

    .md\:self-stretch {
        align-self: stretch !important
    }

    .md\:justify-start {
        justify-content: flex-start !important
    }

    .md\:justify-end {
        justify-content: flex-end !important
    }

    .md\:justify-center {
        justify-content: center !important
    }

    .md\:justify-between {
        justify-content: space-between !important
    }

    .md\:justify-around {
        justify-content: space-around !important
    }

    .md\:content-center {
        align-content: center !important
    }

    .md\:content-start {
        align-content: flex-start !important
    }

    .md\:content-end {
        align-content: flex-end !important
    }

    .md\:content-between {
        align-content: space-between !important
    }

    .md\:content-around {
        align-content: space-around !important
    }

    .md\:flex-1 {
        flex: 1 1 0% !important
    }

    .md\:flex-auto {
        flex: 1 1 auto !important
    }

    .md\:flex-initial {
        flex: 0 1 auto !important
    }

    .md\:flex-none {
        flex: none !important
    }

    .md\:flex-grow {
        flex-grow: 1 !important
    }

    .md\:flex-shrink {
        flex-shrink: 1 !important
    }

    .md\:flex-no-grow {
        flex-grow: 0 !important
    }

    .md\:flex-no-shrink {
        flex-shrink: 0 !important
    }
}

@media (min-width: 992px) {
    .lg\:flex {
        display: flex !important
    }

    .lg\:inline-flex {
        display: inline-flex !important
    }

    .lg\:flex-row {
        flex-direction: row !important
    }

    .lg\:flex-row-reverse {
        flex-direction: row-reverse !important
    }

    .lg\:flex-col {
        flex-direction: column !important
    }

    .lg\:flex-col-reverse {
        flex-direction: column-reverse !important
    }

    .lg\:flex-wrap {
        flex-wrap: wrap !important
    }

    .lg\:flex-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .lg\:flex-no-wrap {
        flex-wrap: nowrap !important
    }

    .lg\:items-start {
        align-items: flex-start !important
    }

    .lg\:items-end {
        align-items: flex-end !important
    }

    .lg\:items-center {
        align-items: center !important
    }

    .lg\:items-baseline {
        align-items: baseline !important
    }

    .lg\:items-stretch {
        align-items: stretch !important
    }

    .lg\:self-auto {
        align-self: auto !important
    }

    .lg\:self-start {
        align-self: flex-start !important
    }

    .lg\:self-end {
        align-self: flex-end !important
    }

    .lg\:self-center {
        align-self: center !important
    }

    .lg\:self-stretch {
        align-self: stretch !important
    }

    .lg\:justify-start {
        justify-content: flex-start !important
    }

    .lg\:justify-end {
        justify-content: flex-end !important
    }

    .lg\:justify-center {
        justify-content: center !important
    }

    .lg\:justify-between {
        justify-content: space-between !important
    }

    .lg\:justify-around {
        justify-content: space-around !important
    }

    .lg\:content-center {
        align-content: center !important
    }

    .lg\:content-start {
        align-content: flex-start !important
    }

    .lg\:content-end {
        align-content: flex-end !important
    }

    .lg\:content-between {
        align-content: space-between !important
    }

    .lg\:content-around {
        align-content: space-around !important
    }

    .lg\:flex-1 {
        flex: 1 1 0% !important
    }

    .lg\:flex-auto {
        flex: 1 1 auto !important
    }

    .lg\:flex-initial {
        flex: 0 1 auto !important
    }

    .lg\:flex-none {
        flex: none !important
    }

    .lg\:flex-grow {
        flex-grow: 1 !important
    }

    .lg\:flex-shrink {
        flex-shrink: 1 !important
    }

    .lg\:flex-no-grow {
        flex-grow: 0 !important
    }

    .lg\:flex-no-shrink {
        flex-shrink: 0 !important
    }
}

@media (min-width: 1200px) {
    .xl\:flex {
        display: flex !important
    }

    .xl\:inline-flex {
        display: inline-flex !important
    }

    .xl\:flex-row {
        flex-direction: row !important
    }

    .xl\:flex-row-reverse {
        flex-direction: row-reverse !important
    }

    .xl\:flex-col {
        flex-direction: column !important
    }

    .xl\:flex-col-reverse {
        flex-direction: column-reverse !important
    }

    .xl\:flex-wrap {
        flex-wrap: wrap !important
    }

    .xl\:flex-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .xl\:flex-no-wrap {
        flex-wrap: nowrap !important
    }

    .xl\:items-start {
        align-items: flex-start !important
    }

    .xl\:items-end {
        align-items: flex-end !important
    }

    .xl\:items-center {
        align-items: center !important
    }

    .xl\:items-baseline {
        align-items: baseline !important
    }

    .xl\:items-stretch {
        align-items: stretch !important
    }

    .xl\:self-auto {
        align-self: auto !important
    }

    .xl\:self-start {
        align-self: flex-start !important
    }

    .xl\:self-end {
        align-self: flex-end !important
    }

    .xl\:self-center {
        align-self: center !important
    }

    .xl\:self-stretch {
        align-self: stretch !important
    }

    .xl\:justify-start {
        justify-content: flex-start !important
    }

    .xl\:justify-end {
        justify-content: flex-end !important
    }

    .xl\:justify-center {
        justify-content: center !important
    }

    .xl\:justify-between {
        justify-content: space-between !important
    }

    .xl\:justify-around {
        justify-content: space-around !important
    }

    .xl\:content-center {
        align-content: center !important
    }

    .xl\:content-start {
        align-content: flex-start !important
    }

    .xl\:content-end {
        align-content: flex-end !important
    }

    .xl\:content-between {
        align-content: space-between !important
    }

    .xl\:content-around {
        align-content: space-around !important
    }

    .xl\:flex-1 {
        flex: 1 1 0% !important
    }

    .xl\:flex-auto {
        flex: 1 1 auto !important
    }

    .xl\:flex-initial {
        flex: 0 1 auto !important
    }

    .xl\:flex-none {
        flex: none !important
    }

    .xl\:flex-grow {
        flex-grow: 1 !important
    }

    .xl\:flex-shrink {
        flex-shrink: 1 !important
    }

    .xl\:flex-no-grow {
        flex-grow: 0 !important
    }

    .xl\:flex-no-shrink {
        flex-shrink: 0 !important
    }
}